<template>
  <b-container id="app" fluid>
    <b-row>
      <b-col cols="12" class="m-0 p-0">
        <router-view/>
      </b-col>
    </b-row>
</b-container>    
</template>
<script>

export default {
  name: "app" 
}
</script>

<style lang="scss">
@import "./assets/_custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
</style>

<style>
.btn-secondary, .btn-primary, .btn-secondary.disabled, .btn-secondary:disabled, .btn-primary.disabled, .btn-primary:disabled {
  color: #fff;  
}
.table-responsive table.b-table {
  min-height: 160px;
}

</style>
